import { FC } from 'react';

import { setGlobalError } from 'reducers/globalErrorSlice';
import { useAppDispatch } from 'store';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import AccentIcon from 'ui-kit/accent-icon/accent-icon';
import Button from 'ui-kit/button';
import { TypeButton } from 'ui-kit/button/type';
import { NotFoundIcon } from 'ui-kit/icons';

import { Links } from 'types/links';

import styles from './styles.module.scss';

const NotFoundContent: FC = () => {
  const dispatch = useAppDispatch();

  const router = useCustomRouter();

  const resetGlobalError = () => {
    router.replace(Links.home);

    dispatch(setGlobalError());
  };

  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h1 className={styles.errorCode}>404</h1>
        <div className={styles.informationContainer}>
          <p className={styles.headline}>Такой страницы нет</p>
          <br /> Похоже, вы потерялись или допустили опечатку, набирая адрес.
          <br /> А может, перешли по неисправной ссылке.
        </div>
        <Button
          type={TypeButton.button}
          className={styles.button}
          onClick={resetGlobalError}>
          На главную
        </Button>
      </div>

      <div className={styles.imageWrapper}>
        <AccentIcon icon={<NotFoundIcon />} />
      </div>
    </div>
  );
};

export default NotFoundContent;
